body.phone {
  padding: 0px;
}
.mobile {
  margin-right: auto;
  margin-left: auto;
  width: 90vw;
}
.mobile .header {
  width: 90vw;
  height: auto;
}
.mobile .header .logo {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  float: none;
}
.mobile .header .fastcontact {
  width: 100%;
  float: none;
}
.mobile .header .fastcontact table.open {
  float: left;
  margin-right: 10px;
}
.mobile .header .fastcontact table.contact {
  float: right;
}
.mobile .view {
  width: 90vw;
}
.mobile .view .content {
  width: 90vw;
}
.mobile .view .content #slider,
.mobile .view .content .slider {
  width: 454px;
}
.mobile .view .content .title {
  margin-top: 20px;
}
.mobile .view .content .slider .description {
  right: 10px;
  bottom: 10px;
  left: auto;
  top: auto;
  min-width: auto;
}
.mobile .view .content .slider .description .inner {
  padding: 5px;
}
.mobile .view .content .slider .description h2 {
  font-size: 15px;
  line-height: 17px;
}
.mobile .view .content .slider .description h3 {
  font-size: 13px;
  line-height: 15px;
}
.mobile .footer {
  width: 90vw;
  height: auto;
  margin-right: 0px;
  margin-left: 0px;
}
.mobile .footer .box {
  height: auto;
  padding-bottom: 40px;
}
.mobile .footer .box.left,
.mobile .footer .box.right,
.mobile .footer .box.middle,
.mobile .footer .map {
  margin-left: auto;
  margin-right: auto;
  float: none;
}
.mobile .footer .map {
  cursor: pointer;
  display: block;
  margin-top: 20px;
  width: 80vw;
  height: 29.09vw;
  background-size: contain;
  background-repeat: no-repeat;
}
.mobile .footer .map .options {
  margin-top: 29.09vw;
  padding-top: 5px;
}
.mobile .footer .imprint {
  white-space: normal;
}
